<template>
  <mds-tag
    :class="`tag--${status.toLowerCase()}`"
    :text="status"
  />
</template>

<script>
import { MdsTag } from '@mds/tag';

export default {
  name: 'StatusTag',
  components: {
    MdsTag,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  &--active {
    background-color: $mds-feedback-color-success;
    color: $mds-background-color-white;
  }
  &--inactive {
    background-color: $mds-feedback-color-info;
  }
  &--running {
    background-color: $mds-feedback-color-warning;
  }
  &--downloading {
    background-color: $mds-feedback-color-warning;
  }
  &--completed {
    background-color: $mds-feedback-color-success;
    color: $mds-background-color-white;
  }
  &--timed_out {
    background-color: $mds-feedback-color-warning;
  }
  &--completed_with_error {
    background-color: $mds-feedback-color-error;
    color: $mds-background-color-white;
  }
  &--error_downloading {
    background-color: $mds-feedback-color-error;
    color: $mds-background-color-white;
  }
  &--provider_inactive {
    background-color: $mds-feedback-color-error;
    color: $mds-background-color-white;
  }
  &--true {
    background-color: $mds-feedback-color-success;
    color: $mds-background-color-white;
  }
  &--false {
    background-color: $mds-feedback-color-warning;
  }
  &--unroled {
    background-color: $mds-feedback-color-warning;
  }
}
</style>
